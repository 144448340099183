<template>
  <a
    v-if="trackingId"
    target="_blank"
    :href="`/v2/goods-issue/detail?tracking_id=${trackingId}`"
  >
    {{ trackingId }}
  </a>
</template>

<script>
export default {
  name: "OrderTracking",
  props: {
    trackingId: {
      type: String,
      default: () => "",
    },
  },
};
</script>

<style scoped></style>
